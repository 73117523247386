<!--商品-->
<template lang="">
<div style="height:100%;padding:0 20px 10px;box-sizing:border-box">
    <div class="selectionBar">
        <div class="flex flex-ai-c flex-wrap">
            <label>场景：</label>
            <el-select v-model="select.cate_id" placeholder="分类" size="small" @change="getList()">
                <el-option v-for="(item,index) in typeList" :key="index" :label="item.cate_name" :value="item.id"></el-option>
            </el-select>
            <el-select v-model="select.name" filterable placeholder="名称" size="small">
                <el-option v-for="(item,index) in list" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <div class="flex flex-ai-c" style="marginLeft:auto">
                <el-radio-group v-model="radio" style="margin-right:10px">
                    <el-radio label="year">年</el-radio>
                    <el-radio label="month">月</el-radio>
                    <el-radio label="week">周</el-radio>
                </el-radio-group>

                <el-date-picker v-model="inputyear" size="mini" type="year" :clearable="false" value-format="yyyy" format="yyyy年" placeholder="选择年" @change="getData()" v-show="radio=='year'">
                </el-date-picker>

                <el-date-picker v-model="inputmonth" size="mini" type="month" :clearable="false" value-format="yyyy-MM-dd" format="yyyy年MM月" placeholder="选择月" @change="getData()" v-show="radio=='month'">
                </el-date-picker>

                <el-date-picker v-model="inputweek" size="mini" type="week" :clearable="false" value-format="yyyy-MM-dd" format="yyyy 第 WW 周" placeholder="选择周" @change="getData()" v-show="radio=='week'">
                </el-date-picker>
            </div>
        </div>
    </div>
    <!-- 表格 -->
    <div class="table" style="height:calc( 100% - 160px );overflow-y:auto">
        <el-row :gutter="10" style="height:100%">
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" style="height:100%;marginBottom:20px;">
                <div class="t1">
                    <div id="bar" :style="{width: '98%', height: '90%',marginTop:'5%'}"></div>
                </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" style="height:100%;marginBottom:20px;">
                <div class="t2">
                    <div id="pie" :style="{width: '98%', height: '90%',marginTop:'5%'}"></div>
                </div>
            </el-col>
        </el-row>
    </div>

</div>
</template>

<script>
var echarts = require('echarts');
export default {
    data: function() {
        return {
            select: {
                cate_id:null,
                name:null
            },
            list: [],

            radio: 'month', //年月周-默认周
            input: '', //时间选择框
            inputyear: '',
            inputmonth: '',
            inputweek: '',
            inputquarter: '', //季度
            detail: {},
            goodsId:null,
            typeList: [],
        }
    },
    mounted: function() {
        this.getType();
        this.getList();
        this.init();
        window.onresize = () => { //  根据窗口大小调整曲线大小
            let myChart = echarts.init(document.getElementById('pie'));
            myChart.resize();
            let myChartbar = echarts.init(document.getElementById('bar'));
            myChartbar.resize();
        };
        this.drawPie()
        this.drawBar()
    },
    destroyed() {
        window.onresize = null;
    },
    watch: {
        'select.name': function() {
            this.goodsId=this.select.name;
            this.getData()
        },
        isCollapse() {
            setTimeout(() => {
                let myChart = echarts.init(document.getElementById('pie'));
                myChart.resize();
                let myChartbar = echarts.init(document.getElementById('bar'));
                myChartbar.resize();
            }, 200)
        },
        'radio': function() {
            if (this.radio == 'month') {
                var myDate = new Date();
                var currentMonth1 = myDate.getMonth();
                var firstDay2 = new Date(myDate.getFullYear(), currentMonth1, 1)
                this.inputmonth = this.dataFormat(firstDay2);
            } else if (this.radio == 'week') {
                var current = new Date();
                var currentyear = current.getFullYear();
                var currentMonth2 = current.getMonth();
                var currentDay = current.getDay();
                var currentDate = current.getDate();
                var firstweek = new Date(currentyear, currentMonth2, currentDate - currentDay + 1);
                this.inputweek = this.dataFormat(firstweek);
            } else if (this.radio == 'year') {
                var myDate2 = new Date();
                this.inputyear = String(myDate2.getFullYear());
            } else if (this.radio == 'quarter') {
                var date2 = new Date();
                var nowYear = date2.getFullYear();
                var nowMonth = date2.getMonth(); //当前月
                var quarterStartMonth = 0;
                if (nowMonth < 3) {
                    quarterStartMonth = 1;
                } else if (2 < nowMonth && nowMonth < 6) {
                    quarterStartMonth = 2;
                } else if (5 < nowMonth && nowMonth < 9) {
                    quarterStartMonth = 3;
                } else if (nowMonth > 8) {
                    quarterStartMonth = 4;
                }
                this.defaultValue = nowYear + '-' + quarterStartMonth;
                this.inputquarter = nowYear + '-' + quarterStartMonth;
                this.bus.$on('quarterchange', (value) => {
                    this.inputquarter = value;
                    this.getData();
                })
            }
            this.getData();
        }
    },
    methods: {
        init: function() {
            if (this.radio == 'month') {
                var myDate = new Date();
                var currentMonth3 = myDate.getMonth();
                var firstDay2 = new Date(myDate.getFullYear(), currentMonth3, 1)
                this.inputmonth = this.dataFormat(firstDay2);
                this.getData();
            }
        },
        // 类型
        getType() {
            this.http.post('/admin.category/getList',{},false).then(re => {
                this.typeList = re.data
            })
        },
        getData() {
            let radio;
            var input
            if (this.radio == 'year') {
                input = this.inputyear;
                radio = 3
            } else if (this.radio == 'month') {
                input = this.inputmonth;
                radio = 2
            } else if (this.radio == 'week') {
                input = this.inputweek;
                radio = 3
            } else if (this.radio == 'quarter') {
                input = this.inputquarter
            }
            this.http.post('/admin.statistics/product', {
                id:this.goodsId,
                type: radio,
                date: input,
            }).then(re => {
                this.detail = re.data;
                this.drawBar();
                this.drawPie();
            })
        },

        drawBar() {
            var chartpro = echarts.init(document.getElementById('bar'));
            chartpro.setOption({
                title: {
                    text: '某站点用户访问来源',
                    left: 'center'
                },
                xAxis: {
                    name: '%',
                    type: 'value',
                },
                yAxis: {
                    name: '项目名称',
                    type: 'category',
                    data: ['倒计时是', 'a']
                },
                grid: { //位置
                    left: '3%',
                    right: '40px',
                    bottom: '3%',
                    containLabel: true
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                series: [{
                    data: [20, 40],
                    type: 'bar',
                    name: '进度',
                    top: '5%',
                }]
            })
        },
        drawPie() {
            var pie = echarts.init(document.getElementById('pie'));
            pie.setOption({
                title: {
                    text: '某站点用户访问来源',
                    // subtext: '纯属虚构',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    left: '20px',
                    top: '20px',
                    type: 'scroll',
                },
                series: [{
                    name: '访问来源',
                    type: 'pie',
                    radius: '50%',
                    top: '5%',
                    data: [{
                            value: 1048,
                            name: '搜索引擎'
                        },
                        {
                            value: 735,
                            name: '直接访问'
                        },
                        {
                            value: 580,
                            name: '邮件营销'
                        },
                        {
                            value: 484,
                            name: '联盟广告'
                        },
                        {
                            value: 300,
                            name: '视频广告'
                        }
                    ],
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }]
            })
        },

        getList: function() {
            this.http.post('/admin.product/getProductList', {
                cate_id:this.select.cate_id,//分类
            }).then(re => {
                this.list = re.data.list;
            })
        },
        dataFormat(time) {
            return `${time.getFullYear()}-${time.getMonth() + 1 >= 10 ? (time.getMonth() + 1) : '0' + (time.getMonth() + 1)}-${time.getDate() >= 10 ? time.getDate() : '0' + time.getDate()}`;
        },
    }
}
</script>
<style media="screen">
.ovtitle {
    font-size: 14px;
    color: #303133;
    border-bottom: 1px solid #EBEEF5;
    height: 45px;
    padding: 0 8px;
    display: flex;
    align-items: center;

    >img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }
}

.t1,
.t2 {
    height: 100%;
    background: #fff;
    border: 1px #ddd solid;
    box-sizing: border-box;
    margin-bottom: 20px;
}
</style>
